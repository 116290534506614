import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    root: {
        padding: theme.spacing(5, 0, 5),
        // position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },
    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },
    profile: {
        height: 700,
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            height: 300,
            transform: 'translateX(0rem) translateY(-5rem) rotate(0deg)'
        },
    },
    paragraphTitle: {
        color: '#f2f2f2',
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            margin: '0rem 0rem 0rem 0rem',
            fontSize: '2vh',

            lineHeight: '1.5rem',
        },
    },
    paragraph: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '0.9rem',
        lineHeight: '2.2rem',
        color: '#f2f2f2',
        [theme.breakpoints.down('sm')]: {
            margin: '0rem 0rem 1rem 0rem',
            fontSize: '3vw',
            lineHeight: '4vw',
        },
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        [theme.breakpoints.down('sm')]: {
            height: '40%',
        },
    }
}));

export default function Biography() {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query{
            placeholderImage: file(relativePath: {eq: "20240918_002.jpg"}) {
                childImageSharp {
                    fluid(quality: 15, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }`
    );

    return (
        <Layout siteTitle={'Biography'}>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Biography
                </Typography>
                <Grid container className={classes.root} spacing={2}>

                    <Grid item xs={12} md={6}>
                        <Img fluid={data.placeholderImage.childImageSharp.fluid} className={classes.profile}/>
                        <ul>
                            <Typography variant="h4" className={classes.paragraphTitle} component="li">
                                葵ミシェル /アコースティックギタリスト
                            </Typography>
                            <Typography className={classes.paragraph} component="li">
                                6月28日生まれ 東京都出身
                            </Typography>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ul>
                            <Typography className={classes.paragraph} component='li'>
                                東京都出身<br/>
                                3歳からピアノを始め、15歳の時にギターと出会う。<br/>
                                専門学校ではレコーディングエンジニアリングを学び、卒業後シンガーソングライター『葵ミシェ
                                ル』として活動を始める。<br/>
                                2016年自身初となるミニアルバム『Ride On The WInd』を全国リリース。<br/>
                                数々のイベントへの出演や、他アーティストのサポートギタリストとしても活動の幅を広げる。<br/>
                                2017年シンガーソングライターからアコースティックギタリストへとスタイルを変え、歌詞も歌
                                も無い世界をアコギ1本で表現している。<br/>
                                2021年6月24日には5曲入りアルバム 「Memories Melodies」をリリース。<br/>
                                2022年7月 ロンドンへ5週間に渡りストリートライブ遠征を行う。<br/>
                                2023年 “ Aoi MichelE
                                “へと改名。6月より、ワンマンライブ企画と連動して連続配信リリース。<br/>
                                11月にはシドニーへストリートライブ遠征を行う。<br/>
                                2024年クラウドファウンディングにて支援を募り、142%、2848500円達成。<br/>
                                新アルバム「Beat! Beat! Beat!」を制作、9月24日リリース。<br/>

                                【Works】<br/>
                                ・Rakuten FM パーソナリティ( 2015年6月~2016年12月)<br/>
                                ・片桐舞子(MAY’S) with 葵ミシェル / O-WEST 出演<br/>
                                ・BSフジ「あなたの歌謡リクエスト」稲垣潤一 / サポート<br/>
                                ・Wela Professionals Web CM 会いに行く美容室 vol.1「BORDER over DREAM~LECO 内田聡一郎×葵ミシェル」出演<br/>
                                ・アコースティック・ギター・マガジンVol.89 (2021.7月21日発売) テイラー×葵ミシェル 試奏記事掲載<br/>
                                ・アイカツスターズ!5周年記念アルバム「Resound Stars! -Aikatsu Stars!Acoustic colection-」<br/>
                                「episode solo」「アイカツ☆ステップ!」 Ag<br/>
                                「8月のマリーナ」「Bon Bon Voyage!」編曲 / Ag<br/>
                                スペシャル動画 『アイカツ☆ステップ!』基礎編(ギター)堀越せな×葵ミシェル 出演<br/>
                                ・月刊「現代ギター」 2023年6月号(No.717) アコギ談義 インタビュー記事、楽譜掲載<br/>
                                ・2024年 3月 キヤノンPowerShotV10 アンバサダー就任<br/>
                                ・MBSラジオ「押尾コータローの押しても弾いても」2024年4月12日 ゲスト出演<br/>
                                ・岡田ロビン翔子 / 工藤遥(ex.モーニング娘。) /真野恵里菜 / アイカツスターズ! / 相沢梨紗(でん
                                ぱ組.inc) / などサポートギターやギター指導も行う。<br/>
                            </Typography>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
};
